import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"

const bestManagers = () => {
  return (
    <Layout>
      <SEO
        title="Best Managers in PES 2020"
        description="Find out who the best Managers are in PES 2020 myClub, for both counter-attacking and possession-based football."
      />
      <div className="container">
        <h1>The best Managers in PES 2020</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Best Managers in PES 2020</li>
        </ul>
        <p>
          Finding the perfect manager for your team can be a challenging task in
          PES - it will heavily depend on the players you have in your club and
          your playing style. For this reason, it can be pretty satisfying when
          you finally pick a good manager to stick to.
        </p>
        <p>
          This year, counter-attacking managers seem to be a more popular
          option, as you can easily score a goal if you quickly find a quick
          succession of passes to your striker. However, I prefer
          possession-based football, so I will offer suggestions for both
          philosophies.
        </p>
        <p>
          While this is a rather subjective matter, I believe this list of
          managers will provide some solid options to get started - but don't be
          afraid to keep experimenting with new formations (
          <Link to="/managers/">there are so many managers available</Link>) and
          tactics.
        </p>
        <p>
          Also, don't forget to have a look at all the{" "}
          <Link to="/advanced-instructions/">Advanced Instructions</Link> you
          can use, which can create a big impact on your tactic, as well as each
          player's <Link to="/playing-styles/">Playing Styles</Link>.
        </p>
        <h2 className="bullet">Fernando Santos (4-3-1-2)</h2>
        <TacticalImg
          src={`fernando-santos-offensive-tactic.jpg`}
          alt={`Fernando Santos Offensive Tactic in PES 2020 myClub`}
        />
        <p>
          <small>
            <Link to="/managers/2020/fernando-santos/">
              Fernando Santos - Full Profile
            </Link>
          </small>
        </p>
        <p>
          The man responsible for bringing so much joy to the Portuguese people
          in the Euro 2016 was a very popular choice in PES 2019, and this year
          is no different.
        </p>
        <p>
          This formation does not have wingers, so your best bet is to attack
          through the middle and rely on short passes.
        </p>
        <p>
          If you have an AMF with good passing skills, you can quickly create
          chances with through balls to your 2 strikers. Maximum Support Range
          means you will find your team well spread out in the pitch, making it
          easy to surprise your opponent on the counter.
        </p>
        <p>
          His high defensive line will keep your players pressing high up the
          pitch, which may make your team vulnerable to quick counters as well.
          To protect you from this, you can use the "Deep Defensive Line"
          defensive instruction, which will make your team drop deeper.
        </p>
        <h3>Alternatives to Fernando Santos</h3>
        <p>
          Fernando Santos is one of the game's top managers, so he will often be
          available in the database. However, if you're in a rush and he's not
          there, have a look at the following alternatives:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2020/c-valbuena-2/">C. Valbuena (2)</Link>
          </li>
          <li>
            <Link to="/managers/2020/h-caputto/">H. Caputto</Link>
          </li>
          <li>
            <Link to="/managers/2020/quique-setien/">Quique Setién</Link>
          </li>
        </ul>
        <h2 className="bullet">Niko Kovač (4-2-1-3)</h2>
        <TacticalImg
          src={`n-kovac-offensive-tactic.jpg`}
          alt={`Niko Kovač Offensive Tactic in PES 2020 myClub`}
        />
        <p>
          After playing the demo, I quickly realized that Niko Kovač would be
          the first manager I would get in myClub - luckily, I got him on
          release day. To this day, he is my favorite manager, not only because
          he is a great fit for possession-based football, but also due to the
          team's behavior when defending.
        </p>
        <p>
          I would recommend pacey wingers and a strong Centre Forward working as
          a target man, for link-up plays. If you enjoy 1-2s inside the box, you
          may struggle with this formation, as you won't have another striker
          for this action, but you can use an AMF with the "Hole Player" playing
          style to have more options in the box.
        </p>
        <p>
          In the midfield, I would recommend a CMF that can work as an
          Orchestrator and a DMF that can play as an Anchor Man. Otherwise, you
          may feel that this team is lacking numbers on the defense, when you're
          out of possession.
        </p>
        <p>
          The defensive line is set quite high, so I usually use the "Deep
          Defensive Line" advanced instruction with this manager, unless I find
          a big gap between my defenders and the opposition's attack.
        </p>
        <p>
          I also use 2 full-backs with the "Defensive Full-back" playing style,
          or apply the "Defensive" advanced instruction to at least one of them.
        </p>
        <h3>Alternatives to Niko Kovač</h3>
        <p>
          Since Niko Kovač is no longer the manager of FC Bayern Munich, you
          won't find him in the game if you've just joined the party.
        </p>
        <p>
          Luckily, there are some nice alternatives - here are some managers
          that share most of the same traits:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2020/b-challandes/">B. Challandes</Link>
          </li>
          <li>
            <Link to="/managers/2020/g-henriques-2/">G. Henriques (2)</Link>
          </li>
          <li>
            <Link to="/managers/2020/l-roman-3/">L. Roman (3)</Link>
          </li>
        </ul>
        <h2 className="bullet">C. Valbuena (4-2-2-2)</h2>
        <TacticalImg
          src={`c-valbuena-offensive-tactic.jpg`}
          alt={`C. Valbuena Offensive Tactic in PES 2020 myClub`}
        />
        <p>
          <small>
            <Link to="/managers/2020/c-valbuena/">
              C. Valbuena - Full Profile
            </Link>
          </small>
        </p>
        <p>
          C. Valbuena is an alias for Diego Simeone, the charismatic Argentinian
          who has made a name for himself for creating very aggressive,
          defensive-minded teams, that try to outwork the opposition and
          surprise them on the counter.{" "}
        </p>
        <p>
          The high compactness will keep the team closer together no matter
          what, making it hard for the opposition to keep the ball and create
          chances.
        </p>
        <p>
          This formation will ask its wingers to work as a second line of
          defense on the flanks, so if you notice your opponent relies on wide
          attacks, you can try to neutralize that threat.
        </p>
        <p>
          If you enjoy quick 1-2s in the box, you can use a player as a Second
          Striker or opt for 2 CFs instead - usually combining a strong player
          with a pacey one works best.
        </p>
        <h3>Alternatives to C. Valbuena</h3>
        <p>
          C. Valbuena is often available as he is one of the top managers to
          choose from, but if you're not lucky enough to find his 4-2-2-2
          formation, here are some similar solutions:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2020/a-plantade/">A. Plantade</Link>
          </li>
          <li>
            <Link to="/managers/2020/l-lagerback/">L. Lagerbäck</Link>
          </li>
          <li>
            <Link to="/managers/2020/s-solbakken/">S. Solbakken</Link>
          </li>
        </ul>
        <h2 className="bullet">Ivo Vieira (4-1-2-3)</h2>
        <TacticalImg
          src={`ivo-vieira-offensive-tactic.jpg`}
          alt={`Ivo Vieira Offensive Tactic in PES 2020 myClub`}
        />
        <p>
          <small>
            <Link to="/managers/2020/ivo-vieira/">
              Ivo Vieira - Full Profile
            </Link>
          </small>
        </p>
        <p>
          A very underrated manager in PES 2020, Ivo Vieira will make your team
          defend very aggressively and impose its possession-based football
          against pretty much any opposition.
        </p>
        <p>
          If you take your time building up your plays with short passes, this
          manager can be a great fit for your team. With 2 CMFs that you can
          also use as AMFs, you can create many chances and explore long shots.
        </p>
        <p>
          Defensively, you will need to pay attention to the manager's high
          defensive line - but this is part of his mentality, as he will attempt
          to choke the opposition when out of possession, much like Jürgen
          Klopp's "Gegenpress" (make sure you enable that Advanced Instruction
          and pick players with high stamina).
        </p>
        <h3>Alternatives to Ivo Vieira</h3>
        <p>
          In my experience, Ivo Vieira isn't a manager you will find that often
          in the database. For this reason, have a look at these alternatives:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2020/g-zeitzler/">G. Zeitzler</Link>
          </li>
          <li>
            <Link to="/managers/2020/e-valverde/">E. Valverde</Link>
          </li>
          <li>
            <Link to="/managers/2020/l-roman-2/">L. Roman (2)</Link>
          </li>
          <li>
            <Link to="/managers/2020/m-gallardo/">M. Gallardo</Link>
          </li>
          <li>
            <Link to="/managers/2020/nuno-santos/">Nuno Santos</Link>
          </li>
          <li>
            <Link to="/managers/2020/quique-setien-2/">Quique Setién (2)</Link>
          </li>
        </ul>
        <h2 className="bullet">J. Löw (3-2-2-3)</h2>
        <TacticalImg
          src={`j-low-offensive-tactic.jpg`}
          alt={`J. Löw (Joachim Löw) Offensive Tactic in PES 2020 myClub`}
        />
        <p>
          <small>
            <Link to="/managers/2020/j-low/">J. Löw - Full Profile</Link>
          </small>
        </p>
        <p>
          If you're looking for a more adventurous formation and don't mind
          having 3 at the back, there are some managers that you may like.
        </p>
        <p>
          One of them is Joachim Löw. With only 3 at the back but also 3 up
          front, you're guaranteed to score many goals, but also to concede a
          fair share.
        </p>
        <p>
          While attacking, I suggest using your Centre Forward as a target man,
          supplying through balls to the 2 Second Strikers (who should be
          pacey). I wouldn't pick very offensive-minded players for the LMF and
          RMF role, to add some consistency to the midfield, and they should
          have decent stamina.
        </p>
        <p>
          Defending with 3 Centre Backs can be daunting, but if you play with an
          Orchestrator and/or an Anchor Man, I believe you will still have
          enough manpower on defense.
        </p>
        <p>
          With this manager, players will always be closer together, making it
          hard for the opposition to find space. Playing with this sort of
          formation comes with a learning curve, but it can be pretty rewarding!
        </p>
        <h3>Alternatives to J. Löw</h3>
        <p>
          There are plenty of managers with a similar 3-2-2-3 approach, but
          these are the ones that share the most similarities with J. Löw:
        </p>
        <ul className="grid-list">
          <li>
            <Link to="/managers/2020/r-martinez/">R. Martínez</Link>
          </li>
          <li>
            <Link to="/managers/2020/lito-vidigal/">Lito Vidigal</Link>
          </li>
          <li>
            <Link to="/managers/2020/p-bosz/">P. Bosz</Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default bestManagers
